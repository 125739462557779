<template>
  <validation-observer ref="simpleRules">
    <b-overlay :show="show" rounded="sm">
      <b-form>
        <b-card>
          <b-card-title
            ><h2>{{ $t("ServiceForm.Publish_Service") }}</h2>
          </b-card-title>
          <br />
          <b-row>
            <!--  name-->
            <b-col md="6">
              <b-form-group>
                <label class="required"
                  >{{ $t("ServiceForm.Service_name") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Service name"
                >
                  <b-form-input
                    v-model="createServiceProfile.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ServiceForm.Service_name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="owner_type"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" label-for="owner_type"
                    >{{ $t("ServiceForm.provided_by") }}
                  </label>

                  <v-select
                    id="State"
                    v-model="createServiceProfile.selectedOwnerType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createServiceProfile.OwnerTypeOptionArabic
                        : createServiceProfile.OwnerTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Service country"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" label-for="Country"
                    >{{ $t("ServiceForm.Service_country") }}
                  </label>

                  <v-select
                    id="Country"
                    v-model="createServiceProfile.selectedCountry"
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="
                      retreiveStates(createServiceProfile.selectedCountry.value)
                    "
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Service state"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" label-for="State"
                    >{{ $t("ServiceForm.Service_state") }}
                  </label>

                  <v-select
                    id="State"
                    v-model="createServiceProfile.selectedState"
                    :options="statesOptions"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Service city"
                  rules="required"
                >
                  <label class="required" label-for="City"
                    >{{ $t("ServiceForm.Service_city") }}
                  </label>

                  <b-form-input
                    id="City"
                    v-model="createServiceProfile.city"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ServiceForm.Service_city')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="highlights"
                  :rules="`required|max:${$maxHighlightsProfile}`"
                  vid="myfield"
                >
                  <label class="required" label-for="highlights"
                    >{{ $t("ServiceForm.Brief_overview") }}
                  </label>

                  <b-form-textarea
                    id="highlights"
                    v-model="createServiceProfile.highlights"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ServiceForm.Brief_overview')"
                    rows="2"
                    max-rows="2"
                    no-auto-shrink
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="service provider"
                  rules="required"
                >
                  <label class="required" label-for="Owner-Details"
                    >{{ $t("ServiceForm.description_provider") }}
                  </label>
                  <b-form-textarea
                    id="Owner-Details"
                    v-model="createServiceProfile.OwnerDetails"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ServiceForm.description_provider')"
                    rows="3"
                    max-rows="3"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label class="required" label-for="description"
                  >{{ $t("ServiceForm.description") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="createServiceProfile.description"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ServiceForm.description')"
                    rows="3"
                    max-rows="3"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Industry"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" label-for="Industry"
                    >{{ $t("ServiceForm.industries_belong") }}
                  </label>
                  <div id="app">
                    <treeselect
                      v-model="createServiceProfile.industries"
                      :multiple="true"
                      :placeholder="$t('common.Select')"
                      :options="industries"
                      @input="handeTreeSelect"
                      :normalizer="
                        $store.state.locale.locale == 'ar'
                          ? normalizerArabic
                          : normalizer
                      "
                    />
                  </div>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" label-for="Category">{{
                    $t("ServiceForm.needs_from_sharing")
                  }}</label>
                  <v-select
                    id="Category"
                    v-model="createServiceProfile.selectedCategory"
                    :options="categories"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="resetDataSaleType()"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              v-if="
                createServiceProfile.selectedCategory.value ==
                '62b40b53956d948f90a3940c'
              "
            >
              <validation-provider
                #default="{ errors }"
                name="sale type"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" label-for="saleType"
                    >{{ $t("ServiceForm.sell_service") }}
                  </label>

                  <v-select
                    id="saleType"
                    v-model="createServiceProfile.selectedSaleType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createServiceProfile.SaleTypeOptionArabic
                        : createServiceProfile.SaleTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="
                createServiceProfile.selectedSaleType.value == 1 &&
                createServiceProfile.selectedCategory.value ==
                  '62b40b53956d948f90a3940c'
              "
              md="6"
            >
              <b-row>
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" label-for=""
                        >{{ $t("ServiceForm.Currency") }}
                      </label>
                      <v-select
                        id=""
                        v-model="createServiceProfile.SelectedCurrency"
                        :options="currencies"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Price"
                    rules="|integer"
                  >
                    <b-form-group
                      label=""
                      :state="errors.length > 0 ? false : null"
                    >
                      <label class="required" label-for="Price">{{
                        $t("ServiceForm.Price")
                      }}</label>

                      <b-form-input
                        id="Price"
                        v-model="createServiceProfile.Price"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ServiceForm.Your_Own_Price')"
                        type="number"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Service type "
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" label-for="servicing_location">{{
                    $t("ServiceForm.Service_type")
                  }}</label>
                  <v-select
                    id="servicing_location"
                    v-model="createServiceProfile.servicingLocation"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createServiceProfile.servicingLocationOptionArabic
                        : createServiceProfile.servicingLocationOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="blockchain exchange"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label for="blockchain_exchange" class="required">{{
                    $t("ServiceForm.blockchain_exchange")
                  }}</label>
                  <v-select
                    id="blockchain_exchange"
                    v-model="createServiceProfile.selectedExchange"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createServiceProfile.ExchangeOptionArabic
                        : createServiceProfile.ExchangeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Duration of service"
                rules=""
              >
                <b-form-group
                  label=""
                  label-for=""
                  :state="errors.length > 0 ? false : null"
                >
                  <label class="" label-for="Service-Type">{{
                    $t("ServiceForm.Duration_service")
                  }}</label>
                  <v-select
                    id="Service-Type"
                    v-model="createServiceProfile.serviceType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createServiceProfile.serviceTypeOptionArabic
                        : createServiceProfile.serviceTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ServiceForm.Facebook')"
                label-for="facebook"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Facebook"
                  rules="url"
                >
                  <b-form-input
                    id="facebook"
                    v-model="createServiceProfile.facebookUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://facebook.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ServiceForm.Instagram')"
                label-for="Instagram"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Instagram"
                  rules="url"
                >
                  <b-form-input
                    id="Instagram"
                    v-model="createServiceProfile.twitterUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://Instagram.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ServiceForm.LinkedIn')"
                label-for="linked-in"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LinkedIn"
                  rules="url"
                >
                  <b-form-input
                    id="linked-in"
                    v-model="createServiceProfile.linkedinUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://linkedin.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ServiceForm.Website')"
                label-for="Other"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Other"
                  rules="url"
                >
                  <b-form-input
                    id="Other"
                    v-model="createServiceProfile.OtherUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://other.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="main image"
                  :rules="`required|size:${$sizeFilesProfile}`"
                >
                  <label class="required" for="main_image">{{
                    $t("ServiceForm.main_image")
                  }}</label>
                  <b-form-file
                    id="main_image"
                    v-model="createServiceProfile.icon"
                    :state="errors.length > 0 ? false : null"
                    accept="image/jpeg, image/png, image/gif"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="service images"
                  :rules="`size:${$sizeFilesProfile}`"
                >
                  <label class="" for="service_images">{{
                    $t("ServiceForm.service_images")
                  }}</label>
                  <b-form-file
                    id="service_images"
                    v-model="createServiceProfile.images"
                    accept="image/jpeg, image/png, image/gif"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                    :multiple="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="service documents"
                  :rules="`size:${$sizeFilesProfile}`"
                >
                  <label class="" for="service_documents">{{
                    $t("ServiceForm.service_documents")
                  }}</label>
                  <b-form-file
                    id="service_documents"
                    v-model="createServiceProfile.docouments"
                    accept="application/pdf,
                  application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    required
                    :state="errors.length > 0 ? false : null"
                    multiple
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col cols="12">
              <b-alert
                v-if="errorMessage"
                show
                variant="warning"
                class="warning"
                style="color: red"
                >{{ errorMessage }}</b-alert
              >
              <br />
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ $t("ServiceForm.Submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapGetters } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vue2Dropzone from "vue2-dropzone";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// import { extend } from "vee-validate";
// import { required, max, size } from "vee-validate/dist/rules";
// extend("required", required);
// extend("max", max);
// extend("size", size);
import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BPopover,
  BFormInvalidFeedback,
  BAlert,
  BCardTitle,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    vSelect,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    BFormInvalidFeedback,
    BCardTitle,
    Treeselect,
    // VuePhoneNumberInput,
    ToastificationContent,
    BAlert,
    BOverlay,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const minDate = new Date(today);
    // maxDate.setMonth(maxDate.getDay() + 1);
    // maxDate.setDate(15);
    return {
      show: false,
      errorMessage: "",
      isValidNumber: null,
      isLoading: false,

      dropzoneOptionsIcon: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsImage: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 5,

        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsDocouments: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        maxFiles: 5,

        headers: { "My-Awesome-Header": "header value" },
      },
      createServiceProfile: {
        selectedState: null,
        name: "",
        selectedOwnerType: "",
        OwnerDetails: "",
        highlights: "",
        description: "",
        servicingLocation: "",
        serviceType: "",
        selectedCountry: null,
        city: "",
        selectedIndustry: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        selectedExchange: "",
        selectedCategory: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",

        minDate: minDate,
        icon: [],
        images: [],
        docouments: [],
        industries: [],
        // phoneNumber: "",
        // phoneCode: "",
        // email: "",
        OwnerTypeOption: [
          { value: "individual", text: "Individual" },
          { value: "group", text: "Group" },
        ],
        OwnerTypeOptionArabic: [
          { value: "individual", text: "فرد" },
          { value: "group", text: "مجموعة" },
        ],

        ExchangeOption: [
          { value: "true", text: "Yes" },
          { value: "false", text: "No" },
        ],
        ExchangeOptionArabic: [
          { value: "true", text: "نعم" },
          { value: "false", text: "لا" },
        ],

        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],
        serviceTypeOption: [
          { value: "contract", text: "Contract" },
          { value: "hourly", text: "Hourly" },
          { value: "daily", text: "daily" },
          { value: "weekly", text: "Wekly" },
          { value: "monthly", text: "Monthly" },
          { value: "yearly", text: "Yearly" },
        ],
        serviceTypeOptionArabic: [
          { value: "contract", text: "عقد" },
          { value: "hourly", text: "بالساعة" },
          { value: "daily", text: "يومياً" },
          { value: "weekly", text: "أسبوعياً" },
          { value: "monthly", text: "شهرياً" },
          { value: "yearly", text: "سنوياً" },
        ],
        servicingLocationOption: [
          { value: "online", text: "Online" },
          { value: "offline", text: "Offline" },
        ],
        servicingLocationOptionArabic: [
          { value: "online", text: "أون لاين" },
          { value: "offline", text: "أوف لاين" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getServiceCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      statesOptions: "country/getAllStates",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      //createService: "profile/createService",
      createService: "profile/createService",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
    }),

    resetDataSaleType() {
      this.createServiceProfile.selectedSaleType = "";
      this.createServiceProfile.SelectedCurrency = "";
      this.createServiceProfile.Price = "";
    },

    // configValidate() {
    //   const dictionary = {
    //     en: {
    //       messages: {
    //         required: () => "This field is required",
    //       },
    //     },
    //     ar: {
    //       messages: {
    //         required: "هذا الحقل مطلوب",
    //       },
    //     },
    //   };
    //   localize(dictionary);

    //   // const validator = new Validator({ first_name: "alpha" });

    //   if (this.$store.state.locale.locale == "ar") {
    //     localize("ar");
    //   } else {
    //     localize("en");
    //   }
    // },

    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.createServiceProfile.industries.length = 6;
      }
    },

    // onUpdate(payload) {
    //   // console.log(payload);
    //   this.isValidNumber = payload.isValid;
    //   this.createServiceProfile.phoneCode = payload.countryCode;
    // },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;

          const ideaData = new FormData();
          ideaData.append("name", this.createServiceProfile.name);
          ideaData.append(
            "typeofOwner",
            this.createServiceProfile.selectedOwnerType.value
          );
          ideaData.append(
            "profileCountry",
            this.createServiceProfile.selectedCountry.value
          );
          ideaData.append(
            "profileIndustries",
            JSON.stringify(this.createServiceProfile.industries)
          );
          if (this.createServiceProfile.facebookUrl) {
            ideaData.append("facebook", this.createServiceProfile.facebookUrl);
          }
          if (this.createServiceProfile.twitterUrl) {
            ideaData.append("twitter", this.createServiceProfile.twitterUrl);
          }
          if (this.createServiceProfile.linkedinUrl) {
            ideaData.append("linkedIn", this.createServiceProfile.linkedinUrl);
          }
          if (this.createServiceProfile.OtherUrl) {
            ideaData.append("otherURLS", this.createServiceProfile.OtherUrl);
          }
          ideaData.append(
            "blockchainExchange",
            this.createServiceProfile.selectedExchange.value
          );
          ideaData.append(
            "category",
            this.createServiceProfile.selectedCategory.value
          );

          if (this.createServiceProfile.selectedSaleType) {
            ideaData.append(
              "saleType",
              this.createServiceProfile.selectedSaleType.value
            );
          }

          if (this.createServiceProfile.SelectedCurrency) {
            ideaData.append(
              "currencyId",
              this.createServiceProfile.SelectedCurrency.value
            );
          }
          if (this.createServiceProfile.Price) {
            ideaData.append("priceNumber", this.createServiceProfile.Price);
          }
          if (this.createServiceProfile.serviceType.value) {
            ideaData.append(
              "serviceType",
              this.createServiceProfile.serviceType.value
            );
          }

          ideaData.append(
            "serviceLocation",
            this.createServiceProfile.servicingLocation.value
          );

          ideaData.append("description", this.createServiceProfile.description);
          ideaData.append(
            "detailsofOwner",
            this.createServiceProfile.OwnerDetails
          );
          ideaData.append("highlights", this.createServiceProfile.highlights);
          ideaData.append("profileCity", this.createServiceProfile.city);
          ideaData.append(
            "profileState",
            this.createServiceProfile.selectedState.value
          );
          ideaData.append("profileType", "service");

          ideaData.append("profileIcon", this.createServiceProfile.icon);

          if (this.createServiceProfile.images) {
            for (const i of Object.keys(this.createServiceProfile.images)) {
              ideaData.append(
                "profileImages",
                this.createServiceProfile.images[i]
              );
            }
          }
          if (this.createServiceProfile.docouments) {
            for (const i of Object.keys(this.createServiceProfile.docouments)) {
              ideaData.append(
                "profileDocuments",
                this.createServiceProfile.docouments[i]
              );
            }
          }

          // eslint-disable-next-line no-underscore-dangle
          ideaData.append("userId", this.user._id);
          this.createService(ideaData)

            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("messages.Added_successfully"),
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.show = false;

              this.$router.push({ name: "service-listAll" });
            })
            .catch((error) => {
              this.show = false;
              this.errorMessage = error.response.data.error.message;
            });
        }
      });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();
    // this.globalHelper();
  },
};
</script>

<style type="text/css">
@charset "UTF-8";

[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.required:after {
  content: " *";
  color: red;
}
[dir] .dropzone.dz-clickable {
  cursor: pointer;
  min-height: 150px;
  max-height: 260px;
  overflow-y: scroll;
}
</style>
